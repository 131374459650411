import {useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function testTest() {
    console.log("test test")
}

export {
    useQuery,
    testTest

}