import React, {useEffect, useState} from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import EmojiPeople from "@material-ui/icons/EmojiPeople";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import UITextFieldWithValidation from "../../components/form/UITextFieldWithValidation";
import Button from "@material-ui/core/Button";
import { database } from "../../firebase/firebase";
import { useStyles } from "../../styles";
import { useQuery } from "../../helpers";
import { useHistory } from "react-router-dom";
import UITextFieldAutoComplete from "../../components/form/UITextFieldAutoComplete";

export default function CreateRecipient(props) {
    let history = useHistory();
    const classes = useStyles();
    const customerPhone = useQuery().get("customerPhone");
    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [wardsList, setWardsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        address: "",
        ward: "",
        city: "",
        state: "",
        phone: "",
        provinceID: "",
        districtID: "",
        refPhone: customerPhone
    });
    useEffect(async () => {
        const getData=()=> {
            fetch("/vietnamlocations.json", {
                headers:{
                    "Content-Type":"application/json",
                    "Accept": "application/json"
                }
            }).then(function(response){
                return response.json();
            }).then(function(myjson){
                const extractedProvincesData = myjson.map((province) => {
                    return {
                        id: province.Id,
                        name: province.Name.replace("Thành phố", "").replace("Tỉnh", "").trim()
                    }
                });
                setProvincesList(extractedProvincesData);

                if(state.provinceID !== '') {
                    console.log(state.provinceID);
                    let districts = myjson.find(province => province.Id === state.provinceID).Districts;
                    console.log(districts);
                    const extractedDistrictsData = districts.map((district) => {
                        return {
                            id: district.Id,
                            name: district.Name
                        }
                    });
                    setDistrictsList(extractedDistrictsData);
                }
                if(state.districtID !== '') {
                    const districts = myjson.find(province => province.Id === state.provinceID).Districts;
                    const wards = districts.find(district => district.Id === state.districtID).Wards;
                    const extractedWardsData = wards.map((ward) => {
                        return {
                            id: ward.Id,
                            name: ward.Name
                        }
                    });
                    setWardsList(extractedWardsData);
                }
            });
        }
        getData();

        setIsLoading(false);
    }, [state.provinceID, state.districtID])

    const handleProvinceChange = () => (event, value) => {
        const inputValue = value ? value.name : '';
        const proID = value ? value.id : '';
        setState({
            ...state,
            state: inputValue,
            provinceID: proID,
            districtID: '',

        })


    };
    const handleDistrictChange = () => (event, value) => {
        const inputValue = value ? value.name : '';
        const distID = value ? value.id : '';
        setState({
            ...state,
            city: inputValue,
            districtID: distID
        })

    };
    const handleWardChange = () => (event, value) => {
        const inputValue = value ? value.name : '';
        setState({
            ...state,
            ward: inputValue,
        })

    };



    function SignUp(e) {
        e.preventDefault();
        let isFormValid = true;
        for(let key in state) {
            if(state[key] === '') {
                isFormValid = false;
                break;
            }
        }
        if(isFormValid) {
            console.log("valid");
            database.collection("recipients").add({
                firstName: state.firstName.trim(),
                lastName: state.lastName.trim(),
                address: state.address.trim(),
                city: state.ward.trim() + ', ' + state.city.trim(),
                state: state.state.trim(),
                phone: state.phone.trim(),
                refPhone: state.refPhone.trim()
            }).then(
                res => {
                    history.replace(`/recipient/select?customerPhone=${customerPhone}`);
                }
            )
        }
    }
    if(!isLoading) {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <EmojiPeople/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Thông Tin Người Nhận
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={SignUp}>
                        <Grid container spacing={2}>
                            <UITextFieldWithValidation autoComplete="lname" name="lastName" label="Họ" halfSize
                                                       state={[state, setState]} type="string"/>
                            <UITextFieldWithValidation autoComplete="fname" name="firstName" label="Tên" halfSize
                                                       state={[state, setState]} type="string"/>
                            <UITextFieldWithValidation autoComplete="street-address" name="address" label="Địa Chỉ"
                                                       state={[state, setState]} type="address"/>
                            <UITextFieldAutoComplete
                                options={provincesList}
                                label="Tỉnh"
                                onChange={handleProvinceChange()}
                                getOptionLabel={(option)=>option.name}
                            />
                            <UITextFieldAutoComplete
                                options={districtsList}
                                label="Quận/Huyện"
                                onChange={handleDistrictChange()}
                                getOptionLabel={(option)=>option.name}
                            />
                            <UITextFieldAutoComplete
                                options={wardsList}
                                label="Phường/Xã"
                                onChange={handleWardChange()}
                                getOptionLabel={(option)=>option.name}
                            />
                            <UITextFieldWithValidation autoComplete="phone" name="phone" label="Số Điện Thoại"
                                                       state={[state, setState]} type="number" inputMode="numeric"/>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={SignUp}
                        >
                            Tạo
                        </Button>
                    </form>
                    <Container>
                        <Typography component="div" style={{height: '200px' }} />
                    </Container>
                </div>
            </Container>
        )
    }
    else {
        return(
            <div>Đang tải...</div>
        )
    }
}