import './App.css';
import React from "react";
import Layout from "../src/layout/layout";
function App() {

    return (
        <div>
            <Layout/>
        </div>
    );

}

export default App;