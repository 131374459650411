import React, {useState} from 'react';
import './Home.css';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import UITextFieldWithValidation from "../../components/form/UITextFieldWithValidation";
import Container from "@material-ui/core/Container";
export default function Home() {
    const [state,setState] = useState({
        phone: ''
    });
    const history = useHistory();
    function handleLook () {
        if(state.phone !== '') {
            history.replace(`/loggedin?customerPhone=${state.phone}`);
        }
    }


    return (
        <Container maxWidth="sm">
            <div className="lookup-form">
                <form noValidate autoComplete="off" onSubmit={handleLook}>
                    <UITextFieldWithValidation name="phone" label="Số Điện Thoại" halfSize state={[state, setState]} inputMode="numeric" type="number"/>
                    <Button color="primary" className="find-button" onClick={handleLook} variant="contained">Tìm</Button>
                </form>
            </div>
        </Container>
    );
}