import TextField from "@material-ui/core/TextField";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";


export default function UITextFieldAutoComplete(props) {

    const halfSize = props.halfSize;
    const label = props.label;
    return(
        <Grid item xs={12} sm={halfSize ? 6 : 12}>
        <Autocomplete
            filterSelectedOptions
            filterOptions={props.filterOptions}
            onChange={props.onChange}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            renderInput={(params) => (

                    <TextField {...params}
                           variant="outlined"
                           label={label}
                           onChange={props.onChange}
                           className={props.className}
                           autoFocus
                    />
            )}
        />
        </Grid>
    )
}
