import Body from '../layout/body/body';
import React from 'react';
import Header from "./header/header";
function Layout() {
    return (
        <div>
            <Header/>
            <Body />
        </div>
    )
}

export default Layout;