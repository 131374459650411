import React, { useState, useEffect} from 'react';
import { database } from "../../firebase/firebase";
import { Redirect } from 'react-router-dom';
import { useQuery } from '../../helpers';
//


export default function Login() {
    const customerPhone = useQuery().get("customerPhone");
    const [ data, setData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    useEffect(() =>{
        const fetchUserData = async () => {
            const customersRef = database.collection('customers');
            await customersRef.where("phone", "==", customerPhone).get().then(

                res => (res.forEach((doc) => {
                    setData(doc.data());
                    setLoading(false);

                }))
            ).catch(
                (e) => {
                    console.log(e);
                }
            )
                .finally(
                (e)=> {
                    setLoading( false);
                }
            )
        }

        fetchUserData();
        return () => {
            setLoading({});
        }
    }, [customerPhone]);


    if(isLoading) {
        return(
            <div>
                Loading...
            </div>
        )
    }
    if(data !== null) {
        return (
            <Redirect to={`/recipient/select?customerPhone=${customerPhone}`} />
        )
    }
    else {
        //create new profile
        return(
            <Redirect to={`/customer/create?customerPhone=${customerPhone}`}/>
        )
    }
}