import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
export default function InvoiceItemTextField(props) {
    return (
        <FormControl
            fullWidth={props.fullWidth}
        >
            <TextField
                value={props.value}
                onChange={props.onChange}
                helperText={props.innerHTML}
                className={props.className}
                InputProps={props.inputProps}

            />
        </FormControl>
    )
}


function InvoiceItemTextFieldWithAutoComplete(props) {
    return(
        <Autocomplete
            freeSolo
            filterSelectedOptions
            filterOptions={props.filterOptions}
            onChange={props.onChangeAutoComplete}
            options={props.options}
            getOptionLabel={props.getOptionLabel}
            renderInput={(params) => (

                <FormControl fullWidth>
                    <TextField {...params}
                           onChange={props.onChangeTextField}
                           className={props.className}
                           helperText={props.innerHTML}
                    />
                </FormControl>
            )}
        />
    )
}

export {
    InvoiceItemTextFieldWithAutoComplete
}