import React from 'react';
import { useMediaQuery } from 'react-responsive';
import '../../fonts';
import { useHistory } from "react-router-dom";
import 'jspdf-autotable'
import jsPDF from "jspdf";
// import {database} from "../../firebase/firebase";

export default function SendMilkInvoicePDF(props) {
    let history = useHistory();
    const doc = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "letter"
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const initialXMargin = 20;
    printDocument();
    function printCompanyText() {
        doc.setFont("TimesNewRoman", "bold");
        doc.setFontSize(28);
        doc.text("King Cargo", initialXMargin, 35)
    }

    function printAddressText() {
        const spacing = 18;
        let initialX = initialXMargin;
        let initialY = 53;
        doc.setFont("TimesNewRoman", "bold");
        doc.setFontSize(18);
        const address = [
            "935 River St",
            "Honolulu, HI 96817",
            "TEL: 808-277-8961"
        ]
        address.forEach((line) => {
            doc.text(line, initialX, initialY)
            initialY += spacing;
        })

    }

    function printTopRightRect() {
        // doc.rect(4.0, 0.4, 4,0.75, "FD");
        doc.autoTable({
            columnStyles: {
                0: {halign: 'center', valign: 'middle', cellWidth: 180, textColor: 'black', font: "TimesNewRoman"},
                1: {halign: 'center', valign: 'middle', textColor: 'black', font: "TimesNewRoman"},

            },
            body: [
                [{content: "Shipping order - Office", colSpan: 2, styles: {fillColor: '#D3D3D3', fontStyle: 'bold', fontSize: 12, textColor: 'black'}}],
                [{content: "Sữa " + props.hiNo, rowSpan: 4, styles: {fontSize: 20, fontStyle: 'bold', halign: 'left', valign: 'middle'}}, 'Please check one below'],
                [{content: "__ Door to door \n__ Airport\n__ Vietnam Branch Store", styles: {fontSize: 10, fontStyle: 'bold', halign: 'left', valign: 'middle'}}, ""],
                // ...
            ],
            margin: {
                left: 220,
                right: initialXMargin,
                top: 15
            },
            theme: 'grid',
            tableLineWidth: 0.1,
            tableLineColor: 'black',
            bodyStyles: {
                lineColor: 'black'
            }
        });
    }

    function printShipperTable() {
        doc.autoTable({
            columnStyles: {
                0: {halign: 'left', valign: 'middle', cellWidth: 300, textColor: 'black', font: "TimesNewRoman", fontSize: 10},
                1: {halign: 'left', valign: 'middle', textColor: 'black', font: "TimesNewRoman", fontSize: 10},

            },
            body:[
                [{content: "SHIPPER - NGƯỜI GỬI HÀNG", colSpan: 2, styles: {fillColor: '#D3D3D3', fontStyle: 'bold', halign: 'center', fontSize: 12}}],
                ['Họ tên/Name: ' + props.name,'Ngày/Date: ' + props.date],
                ['Địa chỉ/Address: ' + props.address, 'Phone number: ' + props.phone],
                ['City/State/Zip: ' + props.city_zip_state, 'Secondary Number: ']
            ],
            theme: 'grid',
            tableLineWidth: 0.1,
            tableLineColor: 'black',
            bodyStyles: {
                lineColor: 'black'
            },
            margin: {
                left: initialXMargin,
                right: initialXMargin
            },
        })
    }

    function printRecipientTable() {
        doc.autoTable({
            columnStyles: {
                0: {halign: 'left', valign: 'middle', cellWidth: 300, textColor: 'black', font: "TimesNewRoman", fontSize: 10},
                1: {halign: 'left', valign: 'middle', textColor: 'black', font: "TimesNewRoman", fontSize: 10},

            },
            body:[
                [{content: "RECIPIENT - NGƯỜI NHẬN HÀNG", colSpan: 2, styles: {fillColor: '#D3D3D3', fontStyle: 'bold', halign: 'center', fontSize: 12}}],
                ['Họ tên/Name: ' + props.recName, 'Phone number: ' + props.recPhone],
                ['Địa chỉ/Address: ' + props.recAddress, 'City/State/Zip: ' + props.rec_city_state_zip]
            ],
            theme: 'grid',
            tableLineWidth: 0.1,
            tableLineColor: 'black',
            bodyStyles: {
                lineColor: 'black'
            },
            margin: {
                left: initialXMargin,
                right: initialXMargin
            },
        })
    }
    function printItemsTable() {
        let rows = []
        rows.push(
            [
                {content: "LIST ALL CONTENT - LIỆT KÊ HÀNG HÓA", styles: {fillColor: '#D3D3D3', fontStyle: 'bold', halign: 'center', fontSize: 12}},
                {content: "CÂN NẶNG/WEIGHT", styles: {fillColor: '#D3D3D3', fontStyle: 'bold', halign: 'center', fontSize: 12}},
                {content: "THUẾ/FEE", styles: {fillColor: '#D3D3D3', fontStyle: 'bold', halign: 'center', fontSize: 12}}
            ]
        )
        props.items.forEach((item)=> {
            rows.push([
                item.item, item.amount + ' lb','$ '
            ])
        })
        doc.autoTable({
            columnStyles: {
                0: {halign: 'left', valign: 'middle', cellWidth: 350, textColor: 'black', font: "TimesNewRoman", fontSize: 10},
                1: {halign: 'left', valign: 'middle', textColor: 'black', font: "TimesNewRoman", fontSize: 10},
                2: {halign: 'left', valign: 'middle', textColor: 'black', font: "TimesNewRoman", fontSize: 10},

            },
            footStyles: {
                halign: 'left',
                valign: 'middle',
                textColor: 'black',
                font: "TimesNewRoman",
                fontSize: 10, fillColor: 'white',
                fontStyle: 'normal',
                lineWidth: 0.1,
                lineColor: 'black'
            },
            body:rows,
            foot: [
                ['Declared Value/Tổng trị giá lô hàng: ',{content: 'Insurance/Bảo hiểm 3%: ', colSpan: 2}],
                ['Shipping Price/Giá cước: ', {content: 'Total Price/Tổng cộng: ', colSpan: 2}]
            ],
            theme: 'grid',
            tableLineWidth: 0.1,
            tableLineColor: 'black',
            bodyStyles: {
                lineColor: 'black'
            },
            margin: {
                left: initialXMargin,
                right: initialXMargin
            },
        })
    }

    function printAcknowledgementTable() {
        doc.autoTable({
            columnStyles: {
                0: {halign: 'left', valign: 'middle', textColor: 'black', font: "TimesNewRoman"}
            },
            body:[
                [{
                content: "“I CERTIFY THAT THIS CARGO DOES NOT CONTAIN ANY UNAUTHORIZED EXPLOSIVES, INCENDIARIES, OR " +
                    "HAZARDOUS MATERIALS. I CONSENT TO A SEARCH OF THIS CARGO. I AM AWARE THAT THIS ENDORSEMENT AND " +
                    "ORIGINAL SIGNATURE, ALONG WITH OTHER SHIPPING DOCUMENTS, WILL BE RETAINED ON FILE FOR AT LEAST " +
                    "THIRTY DAYS.”\n\n" +
                    "“TÔI XÁC NHẬN RẰNG LÔ HÀNG TRÊN HOÀN TOÀN THEO ĐÚNG LUẬT, QUY ĐỊNH VÀ LỆNH ÁP DỤNG CỦA TỔ " +
                    "CHỨC HÀNG KHÔNG DÂN DỤNG QUỐC TẾ (ICAO) VÀ HIỆP HỘI VẬN CHUYỂN HÀNG KHÔNG QUỐC TẾ (IATA). TÔI XÁC " +
                    "NHẬN RẰNG KHÔNG CÓ BẤT CỨ CHẤT NỔ, KHÍ GAS DỄ GÂY CHÁY HOẶC CHẤT ĐỘC HẠI BẤT HỢP PHÁP VÀ HÀNG BỊ " +
                    "CẤM CHUYÊN CHỞ BỞI LUẬT, QUY ĐỊNH HOẶC LỆNH ÁP DỤNG CỦA QUỐC GIA HOẶC QUỐC TẾ TRONG LÔ HÀNG " +
                    "TRÊN. TÔI ĐỒNG Ý RẰNG LÔ HÀNG TRÊN SẼ ĐƯỢC KIỂM TRA TRONG TRƯỜNG HỢP KHÔNG CÓ SỰ HIỆN DIỆN CỦA " +
                    "TÔI NHẰM MỤC ĐÍCH XÁC ĐỊNH HÀNG GỬI CÓ CHỨA BẤT KỲ HÀNG HÓA NÀO BỊ CẤM HOẶC KHÔNG ĐƯỢC CHẤP " +
                    "NHẬN MANG LÊN MÁY BAY HAY KHÔNG.”",
                styles: {fontStyle: 'bold', halign: 'left', fontSize: 10}
            }],
            ],
            theme: 'plain',
            margin: {
                left: initialXMargin,
                right: initialXMargin
            },
        })
    }

    function printSignatureTable() {
        doc.autoTable({
            columnStyles: {
                0: {halign: 'left', valign: 'top', textColor: 'black', font: "TimesNewRoman", cellWidth: 280},
                1: {halign: 'left', valign: 'top', textColor: 'black', font: "TimesNewRoman"}

            },
            body:[
                ['Ký tên – Signature of Shipper:\n\n', 'Nhân viên – Authorized Representative Initials:'],
            ],
            theme: 'grid',
            tableLineWidth: 0.1,
            tableLineColor: 'black',
            margin: {
                left: initialXMargin,
                right: initialXMargin,
                bottom: 15
            },
        })
    }

    function printDocument() {
        printCompanyText();
        printAddressText();
        printTopRightRect();
        printShipperTable();
        printRecipientTable();
        printItemsTable();
        printAcknowledgementTable();
        printSignatureTable();
        if(isDesktopOrLaptop) {
            doc.output('dataurlnewwindow');
            history.replace(`/recipient/select?customerPhone=${props.phone}`);
        }
        else {
            doc.save("invoice.pdf");
            history.replace(`/recipient/select?customerPhone=${props.phone}`);
        }
    }

    return (
        <div>

        </div>
    )

}

