import React, {useEffect, useState} from 'react';
import { database } from "../../firebase/firebase";
import { DataGrid } from '@material-ui/data-grid';

const columns = [
    { field: 'customerPhone', headerName: 'Customer', width: 120 },
    { field: 'receiverPhone', headerName: 'Receiver', width: 120 },
    { field: 'date', headerName: 'Date', width: 120 }
];

export default function InvoicesTable() {
    const [ state, setState] = useState({
        invoices: []
    });

    useEffect(() => {
        const dataArray = []
        const invoicesRef = database.collection('invoices');
        invoicesRef.get().then(
            res => (res.forEach((doc) => {
                dataArray.push({id: doc.id, data: doc.data()});
            }))
        ).then(function () {
            setState({
                invoices: dataArray
            })
        })
    }, []);
    let rows = [];
    rows = state.invoices.map((invoice) => {
        return {
            id: invoice.id,
            customerPhone: invoice.data.customerPhone,
            receiverPhone: invoice.data.receiverPhone,
            date: invoice.data.date.toDate().toLocaleDateString()
        }
    })
    function goToPrintInvoice(e) {
        const invoiceID = e.data.id;
        const win = window.open(`/invoice/print?invoiceID=${invoiceID}`, "_blank");
        win.focus();

    }
    return(
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} pageSize={5} onCellClick={goToPrintInvoice}/>
        </div>
    )
}
