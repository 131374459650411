import React, {useState} from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import PersonOutline from "@material-ui/icons/PersonOutline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import UITextFieldWithValidation from "../../components/form/UITextFieldWithValidation";
import Button from "@material-ui/core/Button";
import { database } from "../../firebase/firebase";
import { useStyles } from "../../styles";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../helpers";

export default function CreateCustomer() {
    const classes = useStyles();
    const customerPhone = useQuery().get('customerPhone');
    const history = useHistory();
    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        address: "",
        city: "Honolulu",
        state: "Hawaii",
        zip: "96817",
        phone: customerPhone
    });

    function signUp(e) {
        e.preventDefault();
        let isFormValid = true;
        for(let key in state) {
            if(state[key] === '') {
                isFormValid = false;
                break;
            }
        }
        if(isFormValid) {
            database.collection("customers").add({
                firstName: state.firstName.trim(),
                lastName: state.lastName.trim(),
                address: state.address.trim(),
                city: state.city.trim(),
                state: state.state.trim(),
                zip: state.zip.trim(),
                phone: state.phone.trim()
            }).then(
                res => {
                    history.replace(`/recipient/select?customerPhone=${state.phone}`);
                }
            )
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <PersonOutline />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Thông Tin Khách Hàng
                </Typography>
                <form className={classes.form} noValidate onSubmit={signUp}>
                    <Grid container spacing={2}>
                        <UITextFieldWithValidation autoComplete="lname" name="lastName" label="Họ" halfSize state={[state, setState]} type="string"/>
                        <UITextFieldWithValidation autoComplete="fname" name="firstName" label="Tên" halfSize state={[state, setState]} type="string"/>
                        <UITextFieldWithValidation autoComplete="street-address" name="address" label="Địa Chỉ" state={[state, setState]} type="address"/>
                        <UITextFieldWithValidation autoComplete="city" name="city" label="Thành Phố" halfSize state={[state, setState]} value={state.city} type="string"/>
                        <UITextFieldWithValidation autoComplete="state" name="state" label="Tiểu Bang" halfSize state={[state, setState]} value={state.state} type="string"/>
                        <UITextFieldWithValidation autoComplete="zip" name="zip" label="Zip" halfSize  state={[state, setState]} inputMode="numeric" type="number" value={state.zip}/>
                        <UITextFieldWithValidation autoComplete="phone" name="phone" value={state.phone} label="Số Điện Thoại" inputMode="numeric" halfSize state={[state, setState]} type="number"/>
                    </Grid>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.submit}
                        onClick={signUp}
                    >
                        Đăng Ký
                    </Button>
                    <Button
                        type="button"
                        href="/"
                        variant="contained"
                        color="secondary"
                        fullWidth
                        className={classes.submit}
                    >
                        Hủy Bỏ
                    </Button>
                </form>
            </div>
        </Container>
    )
}