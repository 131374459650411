import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import {matchSorter} from "match-sorter";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { database } from "../../firebase/firebase";
import { useStyles } from "../../styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useQuery } from "../../helpers";
import { useHistory } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InvoiceItemTextField, {InvoiceItemTextFieldWithAutoComplete} from "../../components/form/InvoiceItemTextField";
import update from 'immutability-helper';
import './Invoice.css';
export default function EnterItems(props) {
    let history = useHistory();
    const classes = useStyles();

    const customerPhone = useQuery().get("customerPhone");
    const receiverPhone = useQuery().get("receiverPhone");
    const itemOptions = [
        { item: 'Dầu Thơm'},
        { item: 'Quần Áo'},
        { item: 'Laptop'},
        { item: 'Điện thoại'},
        { item: 'iPad'},
        { item: 'iPhone'},
        { item: 'Vỏ Xách'},
        { item: 'Thuốc tây'},
        { item: 'Thuốc Bổ Vitamin'},
        { item: 'Chocolate'},
        { item: 'Sôcôla'},
        { item: 'Thức ăn đóng hộp'},
    ];
    const [hiNo, setHINo] = React.useState("");
    const [lastHiNo, setLastHiNo] = React.useState("");
    const [isLoading, setLoading] = React.useState(false);
    const [items, setItems] = React.useState([
        {
            amount: '',
            item: ''
        },
    ]);
    useEffect(() =>{
        if(hiNo === "" && isLoading === false) {
            const fetchHiNo = async () => {
                await retrieveLastHiNo();
                setLoading(true);
            }

            fetchHiNo();
        }

    }, [hiNo]);
    const handleChange = (prop, index) => (event, value) => {
        const inputValue = value ? value.item : event.target.value;
        setItems(
            ...[],update(items, {
                [index]: {
                    $set: {
                        ...items[index],[prop]: inputValue,
                    }
                }
            })
        )

    };

    const handleHIChange = (event) => {
        event.preventDefault();
        setHINo(event.currentTarget.value);
    }

    async function retrieveLastHiNo() {
        const invoicesRef = database.collection('invoices');
        await invoicesRef.orderBy("date", "desc").limit(1).get().then(
            (doc) => {
                setHINo(""+(parseInt(doc.docs[0].data().hiNo) + 1));
                setLastHiNo(""+(parseInt(doc.docs[0].data().hiNo) + 1));

            }
        )
    }

    function Submit(e) {
        e.preventDefault();
        let validatedItems = [];
        const date = new Date();
        items.forEach((item)=> {
            if(item.item !== '') {
                validatedItems.push(item);
            }
        })
        if(validatedItems.length === 0) {
            validatedItems = [{item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}];
        }
        console.log(hiNo);
        database.collection("invoices").add({
            customerPhone: customerPhone,
            receiverPhone: receiverPhone,
            date: date,
            items: validatedItems,
            hiNo: hiNo === "" ? lastHiNo : hiNo

        }).then(
            doc => {
                history.replace(`/invoice/print?invoiceID=${doc.id}`);
            }
        )
    }

    function AddNewItem(e) {
        e.preventDefault();
        setItems(
            ...[], update(items, {
                $push: [{
                    amount: "",
                    item: ""
                }]
            })
        )

    }
    function SendMilk(e) {
        e.preventDefault();
        let validatedItems = [];
        const date = new Date();
        items.forEach((item)=> {
            if(item.item !== '') {
                validatedItems.push(item);
            }
        })
        if(validatedItems.length === 0) {
            validatedItems = [{item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}];
        }

        database.collection("invoices").add({
            customerPhone: customerPhone,
            receiverPhone: receiverPhone,
            date: date,
            items: validatedItems,
            hiNo: hiNo

        }).then(
            doc => {
                history.replace(`/invoice/printSendMilk?invoiceID=${doc.id}`);
            }
        )
    }
    const filterOptions = (options, state) => {
        if(state.inputValue.length >= 2) {
            return matchSorter(options, state.inputValue, {keys:['item']});
        }
        else {
            return [];
        }
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <AssignmentOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5" align="center">
                    Trong thùng có những món gì?
                </Typography>
                <form className={classes.form} noValidate onSubmit={Submit}>
                    <div>
                        <List className="styledList">
                            <ListItem className="styledListItem">
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <InvoiceItemTextField
                                            fullWidth={true}
                                            innerHTML="HI #"
                                            value={hiNo}
                                            onChange={handleHIChange}

                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            {
                                items.map((value, i) => (
                                    <ListItem key={i} className="styledListItem">
                                        <Grid container >
                                            <Grid item xs={9} sm={8}>
                                                <InvoiceItemTextFieldWithAutoComplete
                                                    onChangeAutoComplete={handleChange('item', i)}
                                                    onChangeTextField={handleChange('item', i)}
                                                    filterOptions={filterOptions}
                                                    options={itemOptions}
                                                    getOptionLabel={(option)=>option.item}
                                                    innerHTML="Hàng"
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1}>&nbsp;</Grid>
                                            <Grid item xs={2} sm={3}>
                                                <InvoiceItemTextField
                                                    className="right-input"
                                                    inputProps={{
                                                        startAdornment: <InputAdornment position="end">lb</InputAdornment>,
                                                    }}
                                                    onChange={handleChange('amount', i)}
                                                    value={items[i].amount}
                                                    innerHTML="Cân Nặng"
                                                />
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={AddNewItem}
                        >
                            Thêm
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            className={classes.submit}
                            onClick={SendMilk}
                        >
                            Gửi Sữa
                        </Button>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={Submit}
                        >
                            Xong
                        </Button>
                    </div>
                </form>
            </div>
        </Container>
    )
}