import firebase from "firebase/app";
import "firebase/firestore";
// Add the Firebase services that you want to use
const firebaseConfig = {
    apiKey: "AIzaSyCQfATLfFrQpWcDWsOPDUy1UFDK701BP_c",
    authDomain: "kingcargo-fa930.firebaseapp.com",
    databaseURL: "https://kingcargo-fa930.firebaseio.com",
    projectId: "kingcargo-fa930",
    storageBucket: "kingcargo-fa930.appspot.com",
    messagingSenderId: "1012560764609",
    appId: "1:1012560764609:web:511e6c003c3a7b84d37e40",
    measurementId: "G-EPVDXWD9M5"
};
// Initialize Firebase
if(!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
export default firebase;
export const database = firebase.firestore();