import React, {useEffect, useState} from 'react';
import {useQuery} from "../../helpers";
import {database} from "../../firebase/firebase";
import SendMilkInvoicePDF from "./SendMilkInvoicePDF";

export default function PrintSendMilkInvoice() {
    const invoiceID = useQuery().get("invoiceID");
    const date = new Date().toLocaleDateString('en-US');
    const [ state, setState] = useState({
        customerPhone: '',
        receiverPhone: '',
        items: [],
        hiNo: ""
    });
    const [ customerData, setCustomerData ] = useState(null);
    const [ receiverData, setReceiverData ] = useState(null);
    const [isLoading, setLoading] = useState(true);
    useEffect(() =>{
        if(state.customerPhone === '') {
            const fetchInvoiceData = async () => {
                await retrieveInvoiceData();
            }

            fetchInvoiceData();
        }
        if(state.customerPhone !== '' && state.receiverPhone !== '') {
            const fetchCustomerData = async () => {
                if (state.customerPhone !== '') {
                    await retrieveCustomerData();
                    await retrieveRecData();

                }
            }

            fetchCustomerData();
        }
    }, [state]);


    if(isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }
    if(receiverData !== null) {
        let customer = {
            name: customerData.lastName + " " + customerData.firstName,
            address: customerData.address,
            city_state_zip: customerData.city + ', ' + customerData.state + ' ' + customerData.zip,
            phone: customerData.phone
        }
        let recipient = {
            name: receiverData.lastName + " " + receiverData.firstName,
            address: receiverData.address,
            city_state_zip: receiverData.city + ', ' + receiverData.state + ' ',
            phone: receiverData.phone
        }

        return (
            <SendMilkInvoicePDF
                    name={customer.name}
                    address={customer.address}
                    city_zip_state={customer.city_state_zip}
                    phone={customer.phone}
                    recName={recipient.name}
                    recAddress={recipient.address}
                    rec_city_state_zip={recipient.city_state_zip}
                    recPhone={recipient.phone}
                    items={state.items}
                    date={date}
                    hiNo={""}
            />
        )
    }
    else {
        return (
            <div>
                No Data
            </div>
        )
    }

    async function retrieveRecData() {
        const recipientsRef = database.collection('recipients');
        await recipientsRef.where("phone", "==", state.receiverPhone).get().then(
            res => (res.forEach((doc) => {
                setReceiverData(doc.data());
                setLoading(false);
            }))
        )
    }

    async function retrieveCustomerData() {
        const customersRef = database.collection('customers');
        await customersRef.where("phone", "==", state.customerPhone).get().then(
            res => (res.forEach((doc) => {
                setCustomerData(doc.data());
                setLoading(false);
            }))
        )
    }

    async function retrieveInvoiceData() {
        const invoicesRef = database.collection('invoices');
        await invoicesRef.doc(invoiceID).get().then(
            (doc) => {
                setState({
                    customerPhone: doc.data().customerPhone,
                    receiverPhone: doc.data().receiverPhone,
                    items: doc.data().items,
                    hiNo: doc.data().hiNo
                })

            })
    }
}