import React, {useEffect, useState} from 'react';
import InvoicePDF from "./InvoicePDF";
import {useQuery} from "../../helpers";
import {database} from "../../firebase/firebase";

export default function PrintBlankInvoice() {
    const customerPhone = useQuery().get("customerPhone");
    const date = new Date().toLocaleDateString('en-US');
    const [ customerData, setCustomerData ] = useState(null);
    const [isLoading, setLoading] = useState(true);
    useEffect(() =>{

        if(customerPhone !== '') {
            const fetchCustomerData = async () => {
                if (customerPhone !== '') {
                    await retrieveCustomerData(customerPhone);

                }
            }

            fetchCustomerData().then((data)=> {
                return "";
            });
        }
    }, []);


    if(isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }
    if(customerData !== null) {
        let customer = {
            name: customerData.lastName + " " + customerData.firstName,
            address: customerData.address,
            city_state_zip: customerData.city + ', ' + customerData.state + ' ' + customerData.zip,
            phone: customerData.phone
        }

        return (
            <InvoicePDF
                name={customer.name}
                address={customer.address}
                city_zip_state={customer.city_state_zip}
                phone={customer.phone}
                recName=""
                recAddress=""
                rec_city_state_zip=""
                recPhone=""
                items={[{item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}, {item: '', amount: ''}]}
                date={date}
                hiNo={""}
            />
        )
    }
    else {
        return (
            <div>
                No Data
            </div>
        )
    }

    async function retrieveCustomerData(customerPhone) {
        const customersRef = database.collection('customers');
        await customersRef.where("phone", "==", customerPhone).get().then(
            res => (res.forEach((doc) => {
                setCustomerData(doc.data());
                setLoading(false);
            }))
        )
    }
}