import React, { useState, useEffect} from 'react';
import {database} from "../../firebase/firebase";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useQuery } from "../../helpers";
import { useStyles} from "../../styles";

export default function SelectRecipient() {
    const customerPhone = useQuery().get("customerPhone");
    const classes = useStyles();
    const [ state, setState] = useState({
        data: []
    });
    useEffect(() => {
        const recipientsRef = database.collection('recipients');
        const dataArray = [];
        recipientsRef.where("refPhone", "==", customerPhone).get().then(
            res => (res.forEach((doc) => {
                    dataArray.push((doc.data()));
            }))
        ).then(function () {
            setState({
                data: dataArray
            })
        })
    }, [customerPhone]);

    return (
        <Container>
            <h1>Người nhận là:</h1>
            {state.data.map((data, i) => {
                return(
                    <div key={i}>
                        <Button variant="outlined" color="secondary" className={classes.RecipientButton}
                                href={"/invoice/enteritems?customerPhone=" + customerPhone + "&receiverPhone=" + data.phone}>
                            {data.firstName} {data.lastName} | {data.phone}
                        </Button>
                    </div>

                        )
            })}
            <div>
                <Button color="secondary" className={classes.RecipientButton}
                        href={"/invoice/printblank?customerPhone=" + customerPhone}>
                    Để Trống
                </Button>
            </div>
            <Button variant="contained" color="primary" href={`/recipient/create?customerPhone=${customerPhone}`}>Thêm Người Nhận Mới</Button>
        </Container>
    )
}
