import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { database } from "../../firebase/firebase";

function createData(customers) {
    let rows = []
    rows = customers.map((customer) => {

        return {
                name: customer.firstName + ' ' + customer.lastName,
                address: customer.address,
                city: customer.city,
                state: customer.state,
                zip: customer.zip,
                phone: customer.phone
            }

    })
    return rows;
}

class CustomerTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: []
        }
    }


    componentDidMount() {
        let dbCustomers = [];
        database.collection("customers").get()
            .then(
                res => (res.forEach((doc) =>{
                    //console.log(doc.id, '=> ', doc.data())
                    dbCustomers.push(doc.data())
                    this.setState({
                        customers: dbCustomers
                    })
                 }))
            )

    }

    render() {
        let rows = createData(this.state.customers);
        return (
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Address</TableCell>
                            <TableCell align="right">City</TableCell>
                            <TableCell align="right">State</TableCell>
                            <TableCell align="right">Zip Code</TableCell>
                            <TableCell align="right">Phone</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.address}</TableCell>
                                <TableCell align="right">{row.city}</TableCell>
                                <TableCell align="right">{row.state}</TableCell>
                                <TableCell align="right">{row.zip}</TableCell>
                                <TableCell align="right">{row.phone}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
}
export default CustomerTable