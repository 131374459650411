import React from 'react';
import CustomerTable from "../../pages/customer/CustomerTable";
import Home from "../../pages/home/Home";
import {
    BrowserRouter as Router,
    Route, Switch
} from "react-router-dom";
import Login from "../../pages/customer/Login";
import Container from "@material-ui/core/Container";
import CreateRecipient from "../../pages/recipient/CreateRecipient";
import CreateCustomer from "../../pages/customer/CreateCustomer";
import SelectRecipient from "../../pages/recipient/SelectRecipient";
import PrintInvoice from "../../pages/invoice/PrintInvoice";
import PrintBlankInvoice from "../../pages/invoice/PrintBlankInvoice";
import EnterItems from "../../pages/invoice/EnterItems";
import InvoicesTable from "../../pages/invoice/InvoicesTable";
import PrintSendMilkInvoice from "../../pages/invoice/PrintSendMilkInvoice";

function Body() {
    return(
        <Container maxWidth="md" style={{padding: 0}}>
            <Router>
                <div className={ "App-Body" }>
                    <Switch>
                        <Route exact path="/">
                             <Home/>
                        </Route>
                        <Route path="/loggedin">
                            <Login/>
                        </Route>
                        <Route path="/customer/show">
                            <CustomerTable/>
                        </Route>
                        <Route path="/recipient/create">
                            <CreateRecipient/>
                        </Route>
                        <Route path="/recipient/select">
                            <SelectRecipient/>
                        </Route>
                        <Route path="/invoice/enteritems">
                            <EnterItems/>
                        </Route>
                        <Route path="/invoice/show">
                            <InvoicesTable/>
                        </Route>
                        <Route path="/customer/create">
                            <CreateCustomer/>
                        </Route>
                        <Route path="/invoice/print">
                            <PrintInvoice/>
                        </Route>
                        <Route path="/invoice/printblank">
                            <PrintBlankInvoice/>
                        </Route>
                        <Route path="/invoice/printSendMilk">
                            <PrintSendMilkInvoice/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        </Container>

    )
}
export default Body;