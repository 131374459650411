import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

export default function UITextFieldWithValidation(props) {
    const name = props.name;
    const id = props.id;
    const label = props.label;
    const autoComplete = props.autoComplete;
    const halfSize = props.halfSize;
    const value = props.value;
    const type = props.type;
    const inputMode = props.inputMode;
    const [state, setState] = props.state;
    const [isValid, setIsValid] = useState(true);
    const [helperText, setHelperText] = useState('');
    function handleChange(e) {
        const value = e.target.value;
        if (type === "number") {
            if(/^\d+$/.test(value)) {
                setState({
                    ...state,
                    [e.target.name]: value
                })
                setIsValid(true);
                setHelperText('');
            }
            else {
                setState({
                    ...state,
                    [e.target.name]: ''
                })
                setIsValid(false);
                setHelperText('Hãy điền 1 số SĐT hợp lệ E.g. 8081234567')
            }
        }
        else if(type === "string") {
            if((/^[a-zA-Z\W]+$/.test(value)) && !(/[!@#$%^&*()-=_+\\/.,<>`~]/.test(value)) ) {
                setState({
                    ...state,
                    [e.target.name]: value
                })
                setIsValid(true);
                setHelperText('');
            }
            else {
                setState({
                    ...state,
                    [e.target.name]: ''
                })
                setIsValid(false);
                setHelperText('Hãy điền thông tin hợp lệ')
            }

        }
        else {
            setState({
                ...state,
                [e.target.name]: value
            })
        }
    }
    return (
        <Grid item xs={12} sm={halfSize ? 6 : 12}>
            <TextField
                autoComplete={autoComplete}
                name= {name ? name: id}
                variant="outlined"
                required
                error={!isValid}
                fullWidth
                id={id ? id : name}
                label={label}
                autoFocus
                onChange={handleChange}
                value={value}
                helperText={helperText}
                inputProps={{ inputMode: inputMode ? inputMode : 'text'}}
            />
        </Grid>
    )
}